import React, { useContext, useState, useRef } from "react"
import { UserContext } from "../context/userContext"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import ToggleMute from "../components/ToggleMute"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleKeyboard from "../components/ToggleKeyboard"

export default function JobApplicationCheck() {
  const { user, saveUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")
  const [submitText, setSubmitText] = useState("Next")
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const API_URL = process.env.GATSBY_API_URL

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value.toUpperCase(),
    })
  }

  const checkUser = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "access-control-allow-origin, Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      },
      body: JSON.stringify({
        nric: user.nric,
        email: user.email,
      }),
    }

    fetch(API_URL + "/check-user", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then(data => {
        saveUser({
          ...data,
          signature: null,
        })

        navigate("/job-application-options")
      })
      .catch(err => {
        err.json().then(errorMessage => {
          if (errorMessage.message) {
            alert(errorMessage.message)
          } else if (errorMessage.errors) {
            alert(errorMessage.errors)
          }

          setSubmitDisabled(false)
          setSubmitText("Next")
        })
      })
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-3 font-weight-bold mt-4 ml-4">
          Before we proceed...
        </h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          Please input your NRIC to check existing progress.
        </h1>

        <div className="form-group row ml-4">
          <h3 className="col-3 form-check-label font-weight-bold mt-4">NRIC</h3>
          <div className="col-3">
            <input
              type="text"
              name="nric"
              className="form-control"
              onFocus={() => setInputName("nric")}
              autoComplete="off"
              style={{
                transform: "scale(2)",
                marginTop: "2em",
              }}
              value={user.nric}
              onChange={e => saveField(e)}
            />
          </div>
        </div>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleKeyboard
            isActive={user.isKeyboardActive}
            onClick={() => {
              saveUser({ ...user, isKeyboardActive: !user.isKeyboardActive })
            }}
          />
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="p-4 mt-4 d-flex">
          <Button
            size="medium"
            text="Back"
            disabled={submitDisabled}
            onClick={() => navigate("/job-application-start")}
          />
          <Button
            text={submitText}
            size="medium"
            disabled={submitDisabled}
            onClick={() => {
              setSubmitText("Please wait")
              setSubmitDisabled(true)
              checkUser()
            }}
          />
        </div>

        {user.isKeyboardActive && (
          <div
            className="fixed-bottom w-50 p-4"
            style={{
              transform: "scale(1.5)",
              marginLeft: 180,
              height: 320,
            }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} Q W E R T Y U I O P [ ] \\",
                  "{lock} A S D F G H J K L ; ' {enter}",
                  "{shift} Z X C V B N M , . / {shift}",
                  ".com @ {space}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
              }}
            />
          </div>
        )}
      </div>
    </Background>
  )
}
